.footer_wrapper {
  width: 100%;
  height: 350px;
  position: relative;
  background-color: #404040;
}
.wallpaper_container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
}
.wallpaper_container::before,
.wallpaper_container::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.wallpaper_container::before {
  background-color: rgba(0, 0, 0, 0.7);
}
.wallpaper_container::after {
  background-image: linear-gradient(
      180deg,
      rgb(17, 14, 20),
      transparent,
      transparent,
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(
      130deg,
      #0f0f0f,
      transparent,
      transparent,
      rgba(0, 0, 0, 0.6)
    ),
    linear-gradient(310deg, #0f0f0f, transparent, transparent, rgba(0, 0, 0, 0)),
    linear-gradient(240deg, #0f0f0f, transparent, transparent, rgba(0, 0, 0, 0)),
    linear-gradient(360deg, #0f0f0f, transparent, transparent, rgba(0, 0, 0, 0));
}
#wallpaper {
  border-radius: 0;
  width: 100%;
  height: 100%;
}
.footer_text {
  font-size: 20px;
  color: whitesmoke;
  text-align: center;
}
.logo_wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: whitesmoke;
  width: fit-content;
  margin-inline: auto;
  padding: 20px 0 0;
}
.logo_container {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: whitesmoke;
  display: grid;
  place-items: center;
}
.footer_logo {
  width: 35px;
  height: 35px;
}
.footer_name {
  font-size: 20px;
  margin-left: 10px;
}
.donation_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.donation_card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact_div {
  font-size: 20px;
  color: whitesmoke;
  cursor: pointer;
}
.contact_holder {
  height: fit-content;
  font-size: 20px;
  display: flex;
  width: fit-content;
  margin: 20px auto;
  padding-inline: 10%;
  align-items: center;
}
.footer_foot {
  position: absolute;
  bottom: 5px;
  left: 20px;
}
.text {
  color: whitesmoke;
}
.paypal {
  aspect-ratio: 1/1;
  width: 80px;
  border: none;
  border-radius: 50%;
  padding: 20px 0;
  background: whitesmoke;
}
.paypal:focus {
  background-color: transparent;
}
.paypal:hover {
  background-color: aqua;
}

.thanks_to_visitors {
  margin-bottom: 30px;
}
.gmail {
  color: aqua;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.gmail:hover {
  text-decoration: underline;
  color: green;
}

@media (max-width: 768px) {
  .footer_wrapper {
    height: 300px;
  }
  #footer_title {
    font-size: 25px;
  }
  .footer_text {
    font-size: 12px;
  }
  .paypal {
    padding: 7px;
    width: 45px;
    left: 10px;
  }
  .donation_container {
    margin: 10px 0;
  }
  .donation_card img {
    width: 25px;
  }
  .contact_div {
    font-size: 15px;
  }
  .text {
    font-size: 15px;
  }
  .logo_container {
    width: 30px;
    height: 30px;
  }
  .footer_logo {
    width: 20px;
    height: 20px;
  }
  .footer_name {
    font-size: 12px;
  }
  .footer_foot {
    text-align: center;
  }
  .contact_holder {
    margin: -5px auto;
  }
}
