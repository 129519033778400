.watch_wrapper {
  min-height: 60vh;
  background-color: #1f1d1d;
  padding-bottom: 10px;
  color: whitesmoke;
}
.error_btn {
  width: 200px;
  height: 60px;
}
.watch_window_wrapper {
  display: flex;
  flex-direction: column;
}
.watch_window_container {
  display: flex;
}
.watch_window_holder {
  flex: 1;
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: #313131;
}
#watch_window {
  display: block;
  width: 100%;
  aspect-ratio: 1/0.6;
  margin: 0 auto;
  margin-bottom: 20px;
}

.features_wrapper,
.features_buttons_wrapper,
.ep_controller_wrapper {
  display: flex;
  justify-content: space-between;
}
.features_wrapper {
  margin-top: 10px;
  margin-inline: auto;
  width: 95%;
}
.features_buttons_wrapper {
  width: 200px;
  cursor: pointer;
}
.ep_controller_wrapper {
  max-width: 44%;
}
.ep_controller_container {
  font-size: 16px;
  justify-content: space-around;
}
.ep_form {
  margin: 10px auto 30px;
}
.features_buttons {
  font-size: 16px;
  display: flex;
  align-items: center;
}
/* ---------------AddWatchListFeatures----------------- */
.add_watch_list_wrapper {
  margin: 20px 0;
  display: flex;
  align-items: center;
  padding-inline: 40px;
  justify-content: space-around;
  width: 100%;
}
.add_watch_list_features_wrapper {
  display: flex;
  align-items: center;
  margin-right: 35px;
  cursor: pointer;
}
.add_watch_list_features_wrapper:hover {
  color: aquamarine;
}
.ep_controller_wrapper {
  width: 160px;
}
.ep_controller_container {
  display: flex;
  align-items: center;
  width: 60px;
  cursor: pointer;
}
#icon_prev:hover {
  color: rgb(218, 93, 93);
}
#icon_prev:active {
  color: rgb(184, 51, 51);
}
#icon_next:hover {
  color: aquamarine;
}
#icon_next:active {
  color: rgb(62, 197, 152);
}
.watch_page_ep_title {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 95%;
  margin-inline: auto;
  justify-content: space-between;
}
.watch_page_ep_title h2 {
  font-size: 20px;
}
.views_wrapper {
  margin-left: px;
  font-size: 20px;
}
.relation_card {
  width: 50px;
  overflow: auto;
}
.server_wrapper {
  width: 100%;
  margin: 20px 0 20px;
  display: flex;
  padding-inline: 40px;
  border-radius: 13px;
}

.current_ep {
  color: black;
  padding: 30px;
  flex: 1;
  text-align: center;
  background-color: aquamarine;
}
.server_container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: tran;
}

.watch_container {
  order: -1;
  width: 25%;
  aspect-ratio: 1/2;
  overflow-y: auto;
  padding: 10px 20px;
  display: flexbox;
  height: 450px;
}
.ep_request_wrapper {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: space-between;
}
#ep_error {
  color: red;
  margin-left: 10px;
  font-size: 15px;
  width: 100%;
  background: blue;
}
#ep_input {
  width: 110px;
  margin: 10px 0;
}
#eps_button {
  border-radius: 5px;
  width: 23%;
  height: 30px;
  margin: 2px;
}
.watch_wrapper_videos {
  /* overflow-y: auto; */
  width: 100%;
  padding: 10px 10px 0;
  height: 415px;
}
.list_of_ep_title {
  margin-top: 5px;
  margin-bottom: 12px;
  left: 40px;
}
.watch_container_videos {
  height: 90%;
  width: 100%;
  overflow-y: auto;
}
#eps_container {
  display: flex;
  padding: 5px 10px;
  min-height: 50px;
  border: 1px solid #313131;
  align-items: center;
}
#eps_container:nth-child(odd) {
  background-color: #6e6e6e;
}
#eps_container:nth-child(even) {
  background-color: #424241;
}
#eps_button:hover {
  background-color: aquamarine;
}
#eps_button:active,
#eps_button:focus {
  background-color: rgb(28, 143, 104);
}
#eps_container:hover {
  cursor: pointer;
  color: #0f0f0f;
  font-weight: 600;
  background-color: aquamarine;
}
#eps_container:focus,
#eps_container:active {
  background-color: rgb(28, 143, 104);
}

#ep_container_img {
  width: 90px;
  border-radius: 5px;
  margin-right: 15px;
}
.form_ep_chosen {
  margin: 0 0 10px;
  width: 100px;
}
.eps_container h1 {
  width: 20%;
  font-size: 20px;
}
.watch_description_wrapper {
  width: 100%;
  background-position: 25% 30%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.watch_description_wrapper::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 10, 0.6);
}
.watch_description_wrapper::after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #0f0f0f, rgba(0, 0, 0, 0));
}
.watch_description_container {
  position: relative;
  flex: 1;
  z-index: 3;
  display: flex;
  padding: 20px;
  color: whitesmoke;
  margin-bottom: 30px;
}
#related_anime_title {
  margin: 20px 0 0 40px;
}
.show_img {
  margin-right: 30px;
  width: 150px;
  height: 220px;
}
.watch_anime_info {
  display: flex;
  align-items: center;
}
.watch_anime_info h1 {
  font-size: 20px;
  margin: 0 auto;
}
.watch_anime_info_link {
  color: whitesmoke;
  border: 1px solid whitesmoke;
  padding: 5px 10px;
  border-radius: 15px;
  margin-left: 60px;
}
.watch_anime_info_link:hover {
  color: rgb(29, 211, 150);
}
.watch_genres_container {
  flex-wrap: wrap;
  display: flex;
  margin: 10px 0;
  align-items: center;
}
.watch_genres {
  border: 1px solid whitesmoke;
  padding: 3px 7px;
  border-radius: 15px;
  margin: 7px 5px;
}

.related_card:hover {
  cursor: pointer;
}
.related_card h2 {
  font-size: 12px;
}

.related_link {
  font-size: 15px;
  height: fit-content;
  margin: 0 auto;
  border: 1px solid #313130;
  border-radius: 20px;
  background-color: #cecccc;
  padding: 3px 10px;
  color: black;
  width: 47%;
}
.related_link:hover {
  background-color: aquamarine;
  box-shadow: 1px 1px 10px #313130;
  color: #242222;
  transform: translate(-1px, -2px);
}
.choices {
  width: 100%;
  display: flex;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  justify-content: space-between;
  padding-inline: 5px;
  margin-top: -10px;
}
.playButton_container {
  color: whitesmoke;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 0;
  background-image: linear-gradient(
    to top,
    #2c2e30,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.1)
  );
  transition: 0.5s ease-in-out;
  bottom: 0;
  left: 0;
}

.playButton {
  position: relative;
  top: 40%;
  font-size: 40px;
}
.playButton:hover {
  cursor: pointer;
  color: aquamarine;
}
.add_watchlist_wrapper {
  opacity: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 7px;
  bottom: 0;
  border-radius: 10px;
  padding: 3px 5px;
}

.add_watch_list_icons {
  position: relative;
  z-index: 9;
  color: whitesmoke;
  margin: 5px 0;
  font-size: 20px;
}
.add_watch_list_icons:active {
  color: #424241;
}
.hidden_icons {
  transition: 0.3s ease-in-out;
  z-index: 9;
  opacity: 0;
  display: flex;
  flex-direction: column;
}
.format_container {
  font-size: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 0 10px 10px;
  color: #9b9b9b;
}
#format {
  margin-right: 7px;
}
#circle_icon {
  font-size: 5px;
  margin-right: 7px;
}
.recommendations_wrapper {
  margin-top: 30;
  width: 95%;
  margin-inline: auto;
}
#watch_recommendations_title {
  font-size: 25px;
  margin-bottom: 10px;
}
.recommendations_container {
  display: gird;
  grid-auto-flow: column;
  overflow-x: auto;
  column-gap: 15px;
  margin-bottom: 30px;
}
#watch_custom_recommendations_card {
  background-color: #2c2e30;
  width: 100%;
  height: 295px;
  display: grid;
  text-align: center;
}
#watch_custom_recommendations_card:hover {
  cursor: pointer;
}
.img_container {
  position: relative;
  width: 100%;
  height: 200px;
  min-height: 195px;
  max-height: 220px;
}
.status_container {
  position: absolute;
  left: 7px;
  top: 5px;
  font-size: 9px;
  font-weight: bolder;
  color: #0f0f0f;
  border-radius: 10px;
  padding: 3px 5px;
  z-index: 3;
  display: flex;
  align-items: center;
}
#check_circle_icon {
  margin-left: 2px;
}
#pending_icon {
  margin-left: 2px;
  font-size: 12px;
}
.custom_eps_container {
  position: absolute;
  right: 7px;
  bottom: 0;
  font-size: 13px;
  font-weight: bolder;
  color: #0f0f0f;
  border-radius: 10px;
  background-color: aquamarine;
  padding: 3px 5px;
  z-index: 3;
}
.img_container::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
}
.img_container::before {
  background-image: linear-gradient(
    to top,
    #2c2e30,
    transparent,
    transparent,
    rgba(0, 0, 0, 0)
  );
}
.recommendations_img {
  border-radius: 0;
  content-visibility: auto;
  width: 100%;
  min-height: 195px;
  max-height: 220px;
  height: 200px;
}
.recommendations_title {
  font-size: 13px;
  padding-inline: 5px;
}

/* -------------------Characters section ------------------ */

.characters_wrapper {
  width: 95%;
  margin: 30px auto 0;
}
.characters_container {
  display: flex;
  justify-content: space-between;
}
.characters_card {
  border-radius: 5px;
  background-color: #575757;
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.characters_card img {
  content-visibility: auto;
  width: 50px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.characters_field,
.voice_actors_field {
  max-width: 49%;
  align-items: center;
  display: flex;
}
.characters_field img {
  content-visibility: auto;
  margin-right: 10px;
}
.voice_actors_field img {
  content-visibility: auto;
  margin-left: 10px;
}
.characters_card h3 {
  color: whitesmoke;
  font-size: 14px;
}
.characters_card h4 {
  color: #afacac;
  font-size: 10px;
}
.characters_info,
.voice_actors_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page_container {
  width: fit-content;
  display: flex;
  margin: 20px auto 0;
  padding-inline: auto;
}
.page_container button {
  width: 80px;
  margin-right: 7px;
}
.page_button:hover {
  background-color: aquamarine;
}
.page_button:active,
.page_button:focus {
  background-color: rgb(28, 143, 104);
}
/* ----------------Comments section ------------ */
.comments_wrapper {
  width: 100%;
  padding: 20px;
}
.comments_container {
  margin-top: 15px;
  border-radius: 12px;
  padding: 20px 10px;
  background-color: #424241;
  min-height: 30vh;
}
#comments_number {
  margin-bottom: 20px;
  color: whitesmoke;
}
.user_info_card {
  display: flex;
  margin-top: 30px;
}
.comments_holder {
  display: flex;
}

.login {
  color: rgb(6, 119, 81);
}
.login:hover {
  color: aquamarine;
  text-decoration: underline;
  cursor: pointer;
}
.user_img {
  cursor: pointer;
  content-visibility: auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 20px;
}
.content_holder {
  padding-right: 20px;
  width: 100%;
}
.user_info {
  display: flex;
  align-items: center;
}
#user_name {
  position: relative;
}
.comment_h2 {
  font-size: 13px;
  margin-right: 10px;
  color: rgb(219, 217, 217);
  display: block;
  position: relative;
  cursor: pointer;
}
#comment_as {
  position: absolute;
  left: 0;
  top: -15px;
  color: aquamarine;
  font-size: 10px;
  width: 90px;
}
#comment_info {
  position: absolute;
  color: aquamarine;
  font-size: 10px;
  left: 0;
  top: -14px;
  width: 90px;
}
.comment_p {
  color: #7c7c7cbe;
  text-shadow: #707070;
  font-size: 12px;
}
.comment_text {
  color: #9b9a9a;
  font-size: 17px;
}
.comment_fa_button:hover {
  cursor: pointer;
}
.reply_input_container {
  width: 100%;
  display: none;
  margin: 20px 0;
}
.reply_input {
  width: 70%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.reply_input button {
  width: 80px;
  margin-left: calc(80% - 80px);
  margin-top: 10px;
}
.edit_textarea {
  width: 80%;
  padding: 5px 10px;
  border-radius: 10px;
}
.reply_btn {
  width: 120px;
  margin-left: calc(100% - 120px);
}
.modify_container {
  position: relative;
  display: flex;
  margin-top: 10px;
  margin-right: 40px;
  width: 250px;
  align-items: center;
}
#icon_thumb_up {
  margin-right: 10px;
}
.comment_fa_button {
  display: flex;
  margin-right: 40px;
  width: 25px;
}
.modify_holder {
  background-color: beige;
  border-radius: 7px;
  width: 95px;
  height: 50px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modify_holder button,
.more {
  width: 70px;
  font-size: 12px;
  padding: 0;
}
.replies_card {
  margin: 15px 0;
  display: flex;
}

@media (max-width: 550px) {
  .watch_description_container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .watch_info_wrapper {
    margin-top: 30px;
  }
  .watch_anime_info {
    display: grid;
    place-items: center;
  }
  .server_wrapper {
    width: 90%;
    margin: 20px auto;
    padding-inline: 0;
  }
  .watch_anime_info_link {
    margin: 10px auto;
  }
  .type {
    text-align: center;
  }
  .watch_genres_container {
    justify-content: center;
  }
  .choices {
    flex-direction: column;
  }
  .related_link {
    margin: auto;
  }
  .user_img {
    content-visibility: auto;
    margin-right: 10px;
  }
  .show_img {
    content-visibility: auto;
    margin-inline: auto;
  }
  .comments_wrapper {
    padding-inline: 5px;
  }
  .features_buttons_wrapper {
    width: 54%;
  }
  .ep_controller_container {
    font-size: 14px;
  }
  .features_buttons {
    font-size: 14px;
  }
  .views_wrapper {
    margin-left: 15px;
  }
  .add_watch_list_wrapper {
    padding-inline: 0;
  }
  .add_watch_list_features_wrapper {
    flex-direction: column;
    width: fit-content;
    justify-content: center;
    margin: 0 10px;
  }
  #add_watch_list_icon {
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
  .watch_page_ep_title h2 {
    font-size: 15px;
    max-width: 80%;
  }
  .views_wrapper {
    font-size: 15px;
    max-width: 15%;
  }
}
@media (max-width: 768px) {
  .characters_container {
    flex-direction: column;
  }
  #watch_recommendations_title {
    font-size: 20px;
  }
}
@media (max-width: 1000px) {
  .watch_window_container {
    display: flex;
    flex-direction: column;
  }

  .watch_window_holder {
    width: 100%;
    min-height: 420px;
  }
  .watch_container_videos {
    height: 94%;
    order: 1;
    padding: 5px 0;
  }
  .list_of_ep_title {
    margin-left: 5px;
  }
  .watch_window_wrapper {
    display: block;
  }

  #eps_container {
    padding: 10px 40px;
  }
  #ep_container_img {
    content-visibility: auto;
    width: 120px;
    height: 65px;
    border-radius: 5px;
    margin-right: 60px;
  }
  #ep_lable {
    margin-right: 10px;
  }
  .watch_container {
    order: 0;
    aspect-ratio: 1/0.4;
    margin-bottom: 20px;
    width: 100%;
    padding: 10px 20px;
    gap: 5px;
    display: flexbox;
  }
  #eps_button {
    border-radius: 5px;
    width: 7%;
  }
  .watch_description_wrapper {
    width: 100%;
    background-position: 25% 28%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
}
@media (min-width: 1000px) {
  .watch_wrapper_videos {
    order: -1;
    width: 30%;
    height: 100%;
  }
}
@media (min-width: 750px) and (max-width: 1024px) {
  .characters_holder {
    width: 49%;
  }
}
@media (min-width: 1025px) {
  .watch_window_container {
    height: 80vh;
  }
  .characters_holder {
    width: 32%;
  }
  .characters_wrapper {
    min-width: 1000px;
    max-width: 1300px;
  }
}
@media (min-width: 1300px) {
  .watch_window_wrapper {
    flex-direction: row;
  }
  .watch_window_container {
    display: flex;
    flex: 1;
  }
  .watch_description_wrapper {
    width: 30%;
  }
  .watch_description_container {
    flex-direction: column;
    align-items: center;
  }
  .watch_anime_info {
    flex-direction: column;
    text-align: center;
  }
  .watch_anime_info_link {
    margin: 10px 0 15px;
  }
  .watch_genres {
    justify-content: center;
  }
  .watch_synopsis {
    height: 250px;
    overflow-y: auto;
  }
  .reply_input {
    width: 450px;
  }
  .show_img {
    content-visibility: auto;
    margin: 10px auto;
  }
}
