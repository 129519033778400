html {
  scroll-behavior: smooth;
  font-family: "Kanit", serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#loading_screen {
  min-height: 100vh;
}
.display_page_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #222121;
  color: whitesmoke;
}
.wrapperAnime {
  width: 100%;
  height: max-content;
  display: block;
  background-color: beige;
}
header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 40px;
  background-color: #313131;
  height: 55px;
}
.login_container {
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  right: 40px;
  background-color: #808080;
}
.login_container:hover {
  cursor: pointer;
  box-shadow: 5px 5px 5px #313131;
}
.login_container img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
#menu_icon {
  font-size: 40px;
  margin-right: 40px;
}
#menu_icon,
#menu_icon_signout:hover {
  cursor: pointer;
}
#menu_icon_signout {
  font-size: 30px;
  margin-left: 10px;
  color: rgb(7, 150, 102);
}
#menu_icon_signout:hover {
  color: rgb(10, 236, 161);
}
.nav_logo_wrapper {
  display: flex;
  align-items: center;
}
.nav_logo_container {
  width: 40px;
  height: 40px;
  background-color: whitesmoke;
  display: grid;
  place-items: center;
  border-radius: 50%;
  margin-right: 10px;
}
.nav {
  display: none;
}
.show_nav {
  background-color: #313131;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 200px;
  padding: 20px;
}
.show_nav::before {
  content: "";
  width: 90%;
  position: absolute;
  top: 57px;
  height: 2px;
  background-color: whitesmoke;
  display: block;
}
.navbar_button_container {
  display: flex;
  flex-direction: column;
}
.navbar_button:hover {
  cursor: pointer;
}
.navbar_button {
  margin: 0 auto 10px;
  font-size: 15px;
  width: fit-content;
  width: 150px;
}
button {
  width: 90px;
  font-size: 15px;
  padding: 2px;
  border-radius: 20px;
}
button:hover {
  cursor: pointer;
}
input {
  width: 240px;
  font-size: 20px;
  border-radius: 15px;
  padding: 5px 17px;
  border: #313131 solid;
  margin-top: 15px;
}
input:focus {
  background-color: #353434;
  color: white;
}

#appName {
  color: rgb(182, 182, 182);
  text-align: center;
  font-size: 30px;
  font-weight: 400;
}
#strong {
  color: azure;
}
.container {
  width: 100%;
  margin-top: -5px;
  display: block;
  padding: 0;
}

/* TopAnime */
.top_wrapper {
  margin-top: -400px;
}
.top_h3 {
  font-size: 15px;
}
li:nth-child(1) {
  margin-top: 40px;
}
a {
  text-decoration: none;
  /* color: #313131; */
  color: whitesmoke;
}
li {
  list-style: none;
  transition: 0.3s;
  border-radius: 12px;
  width: 80%;
  padding: 7px 0px;
}
li:hover {
  transform: translateY(-10px);
  box-shadow: 5px 10px 15px #575555;
}
#random_quote_title {
  font-size: 25px;
  text-align: center;
}
.random_quote_fact {
  max-width: 100%;
  width: fit-content;
  border-radius: 15px;
  margin: 10px auto;
  padding: 15px;
  height: auto;
}
#random_quote_anime {
  font-size: 20px;
}
#random_quote_character {
  font-size: 17px;
}
#anime_img {
  content-visibility: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  display: block;
  height: 300px;
  border-radius: 15px;
  z-index: -1;
}
/* #anime_img::before {
  content: "";
  position: absolute;
  width: 100%;
  border-radius: 15px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
} */
.quote_container {
  margin: 20px 0 10px;
  display: flex;
  justify-content: space-between;
}
#character_img {
  max-width: 30%;
  flex: 1;
  height: 200px;
  margin-right: 20px;
}
.quote_info {
  flex: 1;
}
.category_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  position: relative;
}
.category_wrapper:before {
  content: "";
  position: absolute;
  bottom: 50%;
  height: 2px;
  background-color: #a8a8a8;
}
.airing::before {
  width: calc(100% - 193px);
  left: 170px;
}
.top::before {
  width: calc(100% - 241px);
  left: 220px;
}
.latest_ep::before {
  width: calc(100% - 310px);
  left: 290px;
}
.category_title {
  font-size: 30px;
  margin-right: 10px;
}
.category_icon {
  font-size: 25px;
}
.show_less_button {
  display: inline-flex;
  justify-content: flex-end;
  background: aquamarine;
  padding: 5px 10px;
  color: black;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  left: 50%;
  top: 0;
  transform: translateX(-70%);
  font-size: 10px;
}
.collections {
  position: relative;
  min-height: 65vh;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  row-gap: 50px;
  margin: 20px auto 40px;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#load_more {
  width: 100px;
  height: fit-content;
  font-size: 15px;
  margin: -30px 0 30px;
}
#hide_load_more {
  display: none;
}
#ep {
  text-align: right;
  font-weight: 600;
}
.card {
  width: 100%;
  display: grid;
  place-items: center;
  height: 380px;
  border-radius: 15px;
  transition: 0.2s;
  min-height: 320px;
  padding-bottom: 10px;
}
img {
  content-visibility: auto;
  border-radius: 15px;
}
.card img {
  content-visibility: auto;
  width: 100%;
  min-height: 250px;
  height: 260px;
}
.card:hover {
  transform: translateY(-5px);
  box-shadow: 5px 10px 15px #888888;
}
.card h1 {
  font-size: 18px;
  text-align: center;
}
#showcase {
  position: fixed;
  font-size: 50px;
  top: 800px;
  right: 50px;
  display: block;
  z-index: 99;
}
#showcase:hover {
  cursor: pointer;
}
#hide {
  display: none;
}
.gif {
  height: 200px;
  width: 200px;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.gif_up {
  top: 50%;
}
.gif_down {
  top: 60%;
}
.error_text {
  display: block;
  text-align: center;
}
.link_container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.link {
  /* border: 1.5px solid #313131; */
  /* border: 1.5px solid whitesmoke; */
  background-color: aliceblue;
  color: #313131;
  border-radius: 20px;
  padding: 5px;
}
.link:hover {
  background-color: aquamarine;
  box-shadow: 1px 1px 10px #313130;
  color: #242222;
  transform: translate(-1px, -2px);
}
.trending_swiper {
  width: 100%;
  height: 400px;
  position: relative;
}
.trending_card {
  width: 100%;
  position: relative;
  height: 100%;
}
#trending_img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.trending_card::before,
.trending_card::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
}
.trending_card::before {
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}
.trending_card::after {
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
      60deg,
      #202020,
      transparent,
      transparent,
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(130deg, #222121, transparent, transparent, rgba(0, 0, 0, 0)),
    linear-gradient(310deg, #222121, transparent, transparent, rgba(0, 0, 0, 0)),
    linear-gradient(240deg, #222222, transparent, transparent, rgba(0, 0, 0, 0)),
    linear-gradient(360deg, #222222, transparent, transparent, rgba(0, 0, 0, 0));
}
.trending_info_container {
  width: 65%;
  color: whitesmoke;
  position: relative;
  z-index: 4;
  margin-top: calc(-400px + 100px);
  margin-left: 50px;
}
.trending_info {
  display: flex;
  align-items: center;
}
.trending_title {
  font-size: 30px;
}
.trending_text {
  margin: 10px 15px 0 0;
}
#circle_play_icon {
  margin-right: 5px;
}
.genre_container {
  display: flex;
  margin: 10px auto;
  flex-wrap: wrap;
}
.genre {
  margin: 0 5px 5px 0;
  background-color: whitesmoke;
  padding: 3px 10px;
  border-radius: 15px;
  color: black;
  display: block;
}

.trending_buttons_container {
  display: flex;
  margin-top: 10px;
}
.watch_trending {
  color: whitesmoke;
  border-radius: 30px;
  padding: 5px;
  font-size: 15px;
  display: block;
  width: 120px;
  padding: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: space-around;
  background-color: rgb(4, 160, 108);
}
.details_trending {
  color: whitesmoke;
  align-items: center;
  border-radius: 30px;
  padding: 5px;
  font-size: 15px;
  display: block;
  width: 80px;
  padding: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: space-around;
  background-color: rgb(124, 124, 124);
}
.navigation_container {
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: 80%;
  left: 75%;
  z-index: 4;
}
.navigation {
  color: whitesmoke;
  border: none;
  border-radius: 30px;
  padding: 5px;
  font-size: 15px;
  display: block;
  width: 80px;
  padding: 10px;
  margin-right: 10px;
  justify-content: space-around;
  background-color: rgb(124, 124, 124);
}
.navigation:nth-child(1):hover {
  background-color: rgb(201, 71, 71);
}
.navigation:nth-child(2):hover {
  background-color: rgb(109, 204, 109);
}
/* .Toastify__toast {
  width: 90%;
  margin-inline: auto;
}
.Toastify__toast-body {
  background-color: red;
  width: 200%;
} */

@media (max-width: 300px) {
  .collections {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
  }
  .link_container {
    flex-direction: column;
  }
  .link {
    width: 60%;
    margin-inline: auto;
    text-align: center;
    margin-bottom: 5px;
  }
  #menu_icon {
    margin-left: -20px;
  }
}
@media (min-width: 301px) and (max-width: 449px) {
  .collections {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
  }
}
@media (max-width: 470px) {
  .collections {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
  }
  #menu_icon {
    margin-right: 16px;
  }
  .login_container {
    background-color: transparent;
    right: 5px;
  }
  .username {
    display: none;
    color: transparent;
  }
  .card {
    padding: 5px;
    height: 420px;
  }
}
@media (min-width: 471px) and (max-width: 699px) {
  #menu_icon {
    display: block;
  }
  .collections {
    grid-template-columns: repeat(3, 1fr);
  }

  .card_img {
    content-visibility: auto;
    height: 90px;
    min-height: 95px;
  }
}
@media (max-width: 768px) {
  .Toastify__toast {
    margin-left: 20px;
    width: 95%;
  }
  .trending_info_container {
    width: 87%;
    margin-left: 20px;
    margin-top: calc(-400px + 80px);
  }
  .trending_title {
    font-size: 20px;
  }
  .description {
    max-height: 40px;
  }
  .navigation_container {
    right: 10%;
  }
}
@media (min-width: 900px) and (max-width: 1299px) {
  .collections {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1300px) {
  .collections {
    grid-template-columns: repeat(7, 1fr);
    column-gap: 40px;
    row-gap: 50px;
  }
  .random_quote_fact {
    max-width: 60%;
  }
}
@media (min-width: 1500px) {
  .collections {
    grid-template-columns: repeat(8, 1fr);
    column-gap: 40px;
    row-gap: 50px;
  }
}
