.search_hint_wrapper {
  width: 100%;
  height: auto;
  background-color: #404040;
}
.search_hint_gif {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 170px;
  height: 170px;
}
.search_hint_container {
  width: 100%;
  height: 100px;
  background-color: #404040;
  color: whitesmoke;
  display: inline-flex;
  align-items: center;
  padding-inline: 20px;
  border-bottom: 1px solid #989898;
}
.search_hint_container:hover {
  cursor: pointer;
  background-color: #585858;
  color: aquamarine;
}
.search_hint_img {
  width: 70px;
  height: 70px;
  margin-right: 50px;
}
.search_hint_status {
  color: #a0a0a0;
}
.search_hint_button {
  display: block;
  width: calc(100% - 30px);
  margin: 0 auto 20px;
  height: 50px;
  background-color: aquamarine;
  border-radius: 0;
}
#no_search_result_text {
  color: whitesmoke;
  text-align: center;
  font-size: 20px;
  margin: 30px 0;
}
@media (min-width: 1000px) {
  .search_hint_wrapper {
    width: 700px;
  }
}
