.authentication_window {
  height: 85vh;
  width: 100%;
  padding: 100px auto 0;
  background-color: #202020;
}
.authentication_wrapper {
  width: 100%;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #202020;
  color: whitesmoke;
  position: relative;
  margin-bottom: 100px;
}
.authentication_wrapper label {
  font-size: 15px;
}
.reset_wrapper {
  width: 100%;
  height: 90vh;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
#reset_password_title {
  margin-bottom: 30px;
}
.reset_wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      to top,
      #202020,
      transparent,
      transparent,
      transparent,
      transparent,
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(
      to bottom,
      #202020,
      transparent,
      transparent,
      transparent,
      transparent,
      transparent,
      rgba(0, 0, 0, 0.8)
    ),
    linear-gradient(
      to right,
      #202020,
      transparent,
      transparent,
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(to left, #202020, transparent, rgba(0, 0, 0, 0));
}
.reset {
  width: 550px;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: whitesmoke;
  position: relative;
  z-index: 2;
  margin-bottom: 100px;
}
.authentication_wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      0deg,
      #202020,
      transparent,
      transparent,
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(90deg, #202020, transparent, transparent, rgba(0, 0, 0, 0)),
    linear-gradient(
      180deg,
      #202020,
      transparent,
      transparent,
      /* transparent,
      transparent, */ transparent,
      transparent,
      transparent,
      transparent,
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(270deg, #202020, transparent, transparent, rgba(0, 0, 0, 0)),
    linear-gradient(360deg, #202020, transparent, transparent, rgba(0, 0, 0, 0));
}
.sukuna_container {
  width: 100%;
  height: 320px;
  position: relative;
}

.sukuna_container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to top,
    #202020,
    transparent,
    rgba(0, 0, 0, 0.1)
  );
}

#sukuna {
  width: 100%;
  height: 100%;
}
.login_wrapper {
  padding-top: 25px;
  width: 100%;
  padding-inline: 25px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 3;
  transform: translate(-50%, -50%);
  top: 55%;
  left: 50%;
}
.login_wrapper h1 {
  text-align: center;
  position: relative;
  z-index: 2;
  font-size: 25px;
}
.input_wrapper {
  margin: 10px 0;
  width: 100%;
}
.small_features {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.remember_me {
  display: flex;
  width: 50%;
  align-items: center;
}
#features_text:hover {
  text-decoration: underline;
  cursor: pointer;
}
.button_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.login_button {
  width: 100%;
  border-radius: 5px;
  margin: 10px auto;
  height: 40px;
  font-size: 17px;
  background-color: aquamarine;
}
.login_button:hover,
.login_button:focus {
  background-color: rgb(35, 177, 130);
  color: whitesmoke;
}
.sign_in_with_google_wrapper {
  display: flex;
  background-color: #1e90ff;
  align-items: center;
  width: 100%;
  height: 35px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
}
.sign_in_with_google_wrapper:hover {
  background-color: #1479dd;
}
.sign_in_with_google_wrapper:active {
  background-color: #1065bb;
  transform: translate(1px, 1px);
}
.sign_in_with_google_img {
  border-radius: 5px;
  width: 40px;
  height: 40px;
}
.sign_in_with_google_text {
  width: 70%;
  font-size: 17px;
  display: grid;
  place-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.swal-button {
  width: 150px;
}
.swal-button:hover {
  background: aquamarine;
}

/* ----------------ChooseAvatar------------- */
.avatar_wrapper {
  color: whitesmoke;
  width: 100%;
  height: 100%;
  background-color: #313131;
  display: grid;
  place-items: center;
  padding-inline: 10px;
}
.avatar_options_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-inline: 20px;
  height: 2 0px;
}
.avatar_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.avatar_img_container {
  position: relative;
}
.check_icon {
  position: absolute;
  border-radius: 50%;
  bottom: 10px;
  right: 20px;
  color: whitesmoke;
  transition: 0.3s ease-in-out;
  background-color: rgb(1, 175, 117);
  display: inline;
}
.avatar_choice {
  display: inline-flex;
  width: fit-content;
  font-size: 25px;
  cursor: pointer;
}
.your_img_box_container {
  width: 100%;
  height: 100%;
  background-color: #5e5e5e;
  color: whitesmoke;
  display: grid;
  place-items: center;
  border-radius: 35px;
  /* margin-bottom: 30px; */
}

.custom_file_upload_input {
  width: fit-content;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 12px;
  cursor: pointer;
  background-color: rgb(43, 209, 154);
  border-radius: 30px;
  transition: 0.3s ease-in-out;
}
.custom_file_upload_input:hover {
  background-color: rgb(15, 15, 15);
}
.file_upload_input {
  display: none;
}
.your_img_avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.submit_container {
  width: 80%;
  height: 30px;
  /* padding-inline: 20px; */
  margin: 10px 10px 20px;
}
.avatar_submit_button {
  font-size: 20px;
  width: 80px;
  height: 100%;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  padding-inline: 10px;
  cursor: pointer;
}
.avatar_submit_button:nth-child(1) {
  background-color: #2e8b57;
}
.avatar_submit_button:nth-child(1):hover {
  background-color: #3cb371;
}
.avatar_submit_button:nth-child(2) {
  background-color: rgb(201, 71, 71);
}
.avatar_submit_button:nth-child(2):hover {
  background-color: #dc143c;
}
/* ----------------------------------------- */
@media (max-width: 300px) {
  #features_text {
    font-size: 11px;
  }
}
@media (max-width: 400px) {
  .login_wrapper {
    margin-top: -60px;
  }
}
@media (max-width: 470px) {
  .authentication_wrapper {
    background-position: 35% 0px;
  }
}
@media (max-width: 600px) {
  .authentication_wrapper label {
    font-size: 11px;
  }
  .input_wrapper {
    margin: 0;
  }
  .reset {
    width: 90%;
  }
}

@media (min-width: 900px) {
  .login_wrapper {
    height: fit-content;
    top: 48%;
  }
  .input_wrapper {
    margin: 0;
  }
}
