.error_container {
  display: grid;
  place-items: center;
  height: 95vh;
  position: relative;
}
.error_img {
  width: 55%;
  height: 55%;
  border-radius: 0%;
}
.error_btn {
  position: absolute;
  bottom: 60px;
  width: 150px;
  font-weight: 600;
  padding: 10px;
  border-radius: 10px;
  color: #313131;
  background-color: rgb(95, 235, 142);
  background-image: linear-gradient(
    45deg,
    #b0f0cb 0%,
    #9feea6 25%,
    rgb(48, 223, 121) 50%,
    rgb(50, 219, 101) 100%
  );
}
.error_btn:hover {
  box-shadow: 4px 4px 10px rgb(51, 216, 119);
  transform: translate(-3px, -3px);
  font-weight: 900;
}
@media (min-width: 800px) {
  .error_img {
    width: 550px;
    height: 550px;
  }
}
